import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = [ "slider", "form" ]
  static values = { rounded: Number }
    
  toggleTastingNoteVisibility() {
    this.roundedValue = Math.round(this.sliderTarget.value)
  }
  
  save() {
    Rails.fire(this.formTarget, "submit")
  }
}
