import { Controller } from "stimulus"
import $ from "jquery"

export default class extends Controller {
  static values = { delay: Number, url: String }
  
  disconnect() {
    clearTimeout(this.timeout)
  }

  delayValueChanged() {
    if (this.delayValue < 60000) {
      this.timeout = setTimeout(() => this.poll(), this.delayValue)
    }
  }

  poll() {
    $.ajax(this.urlValue, { dataType: "html" }).done(data => {
      var parsed = $.parseHTML(data)
      
      var currentUpdatedAt = $("#sample-metadata").data().updatedAt
      var remoteUpdatedAt = $(parsed).filter("#sample-metadata").data().updatedAt

      if (currentUpdatedAt < remoteUpdatedAt) {
        $("#spider").attr("data-spider-graph-data-value", $(parsed).filter("#spider").attr("data-spider-graph-data-value"))
        $("#sample-tasting-notes").replaceWith($(parsed).filter("#sample-tasting-notes"))
        $("#sample-emojis").replaceWith($(parsed).filter("#sample-emojis"))
        $("#sample-metadata").replaceWith($(parsed).filter("#sample-metadata"))
        this.delayValue = Math.round(Math.max(2000, this.delayValue * 0.2))
      } else {
        this.delayValue = Math.round(this.delayValue * 1.8)
      }
    })
  }
}
