import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "template" ]
  static values = { selectedTemplate: Number }
  
  toggleProductListVisibility() {
    this.selectedTemplateValue = this.templateTarget.value
  }
}
