import { Controller } from "stimulus"
import Chart from "chart.js"

export default class extends Controller {
  static targets = [ "canvas" ]
  static values = { data: Object, options: Object }
  
  initialize() {
    Chart.defaults.global.defaultFontFamily = "'domaine', 'sans-serif'";
        
    this.spiderGraph = new Chart(
      this.canvasTarget.getContext("2d"),
      {
        type: "radar",
        options: this.optionsValue
      }
    )
  }

  disconnect() {
    this.spiderGraph.destroy()
  }

  dataValueChanged() {
    this.render()
  }
  
  render() {
    this.spiderGraph.data = this.dataValue
    this.spiderGraph.update()
  }
}
